var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.user)?_c('q-scroll-area',{staticClass:"home",attrs:{"dark":_vm.$store.getters.theme === 'dark',"thumb-style":{ width: '10px' }},on:{"scroll":_vm.handleScroll}},[_c('Header',{attrs:{"scrolled":!!_vm.scrolled}}),(_vm.diceColors.length > 0)?[_c('section',{attrs:{"id":"top"}},[_c('Top',{attrs:{"maintenance":_vm.maintenance}}),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[0] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-10",attrs:{"id":"overview"}},[_c('Feedback'),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[1] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-11",attrs:{"id":"pricing"}},[_c('Pricing'),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[2] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-9",attrs:{"id":"general"}},[_c('General'),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[3] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-10",attrs:{"id":"builder"}},[_c('Builder'),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[4] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-9",attrs:{"id":"share"}},[_c('Share'),_c('span',{staticClass:"die",style:({
					backgroundImage:
						'url(' +
						require('src/assets/_img/logo/logo-icon-no-shield-' + _vm.diceColors[5] + '.svg') +
						')',
					transform: `rotate(${_vm.scrolled}deg)`,
				})})],1),_c('section',{staticClass:"bg-neutral-10",attrs:{"id":"campaign"}},[_c('Campaign')],1),_c('Footer')]:_vm._e()],2):_c('div',{staticClass:"user-content"},[_c('Authenticated',[_c('UserContent')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }